.about-section {
    padding: 0px;
    display: flex;
    /* align-items: center; */
  }
  
  .profile-pic {
    width: 200px;
    border-radius: 50%;
    margin-right: 20px;
  }
  
  .about-text h2 {
    font-size: 2.5rem;
    color: #000;
    font-family: 'Poppins', sans-serif;

  }
  
  .about-text p {
    font-size: 1.2rem;
    color: #000;
    font-family: 'Open Sans', sans-serif;

  }
  
  .skills-list {
    list-style-type: none; /* Remove default bullets */
    padding: 0;
  }
  
  .skills-list li {
    margin-bottom: 10px;
  }
  
  .icon {
    margin-right: 8px;
    color: #4CAF50; /* You can change this color as per your design */
  }
  

  .about-skills-container {
    display: flex;
    justify-content: space-between;
    padding: 40px;
    font-family: Arial, sans-serif;
  }
  
  .about-section {
    flex: 2;
    /* margin-right: 40px; */
  }
  
  .skills-section {
    flex: 1;
  }
  
  .skills-category {
    margin-bottom: 20px;
  }
  
  .skills-category ul {
    list-style: none;
    padding: 0;
  }
  
  .skills-category ul li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 10px;
  }
  
  .skills-category ul li:before {
    content: "✔️";
    position: absolute;
    left: 0;
    color: green;
  }
  
  h2, h3 {
    margin-bottom: 15px;
  }
  
  p {
    margin-bottom: 15px;
    line-height: 1.6;
  }
  
  .about-text {
    display: flex;
    flex-wrap: wrap;
  }
  
  .skills-column {
    /* width: 48%; */
  }
  
  .skills-list {
    list-style: none;
    padding: 0;
  }
  
  .skills-list li {
    margin-bottom: 10px;
  }
  
  .icon {
    color: green; /* Adjust icon color */
    margin-right: 8px;
  }
  
  .skills-column {
    /* flex-basis: 42%; */
    margin-bottom: 20px; /* Adds some spacing below the column for small screens */
  }
  
  .about-text {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    width: 100%;
  }
  