.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px; /* Ensure the header has a defined height */
    z-index: 50; /* Keeps the header above other content */
    background-color: #f5f5dc; /* Ensure background is set */
  }

  main {
    padding-top: 70px; /* Matches the height of the header */
  }
  