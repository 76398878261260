@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}


header ul li {
  @apply px-2;
}

.header {
  /* background: url('assets/images/coding-bg.jpg') no-repeat center center/cover; */
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
}
.app {
  /* background: url('assets/images/coding-bg.jpg') no-repeat center center/cover; */
  /* background: #61584a; */
  /* background: #af9b84; */
  height: 90%;
  margin: 0;
  padding: 0;
  background: #fffdf0;
}
.bg-primary {
  /* background: #c1b7a6;  */
  /* rgb(72, 72, 233); */
  /* background: #e4e5de; */
  /* background: #d1c9b7; */
  /* background: #af9b84; */
  
}

.bg-secondary {
  /* background: #2b2d77; */
  /* background: #fff0ff; */
}

.nav-menu {
  margin-left: auto; /* This will push the menu to the left */
  text-align: left;
}

.nav-menu ul {
  list-style: none;
  display: flex;
  justify-content: flex-start; /* Ensure the menu items are left-aligned */
}

.nav-menu ul li {
  margin-right: 20px;
}

.nav-menu ul li a {
  color: white;
  text-decoration: none;
}

.mobile-nav {
  @apply fixed top-11 left-0  w-full h-1/5;
  background-color: #fffdf0;
}

.mobile-nav li {
  @apply w-full h-full text-center border-b-2;
}

.alternate-section {
  /* background: #b0b6af; */
  /* background: #fffdf0; */
}

body {
  background-color: #fffdf0; /* Set your desired background color */
}