.contact-section {
    padding: 50px;
    text-align: center;
  }
  
  form {
    max-width: 600px;
    margin: 0 auto;
  }
  
  input, textarea {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #bdc3c7;
    border-radius: 5px;
  }
  
  button {
    background-color: #3498db;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  