.projects-section {
    /* padding: 50px; */
  }
  
  .projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 10px;
  }
  
  .project-card {
    /* background-color: #ecf0f1; */
    /* background-color: #fcfaf5; */
    background-color: #f5f5dc;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
  }
  
  .project-card h3 {
    font-size: 1.5rem;
    color: #2c3e50;
  }
  
  .project-card p {
    color: #7f8c8d;
  }
  
  .project-card span {
    display: block;
    margin-top: 10px;
    color: #3498db;
  }
  
  h2 {
    font-size: 2.5rem;
    color: #000;
    font-family: 'Poppins', sans-serif;
  }

  .projects-container {
    padding: 20px;
  }
  
  .projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .project-card {
    /* background-color: #f0f0f0; */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .project-card h3 {
    margin-top: 0;
  }
  
  .project-card p {
    margin-bottom: 10px;
  }
  