.skills-section {
    padding: 50px;
  }
  
  .skills-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .skill-card {
    text-align: center;
  }
  
  .skill-bar {
    background-color: #bdc3c7;
    border-radius: 5px;
    margin-top: 10px;
    position: relative;
    height: 20px;
  }
  
  .skill-level {
    background-color: #3498db;
    height: 100%;
    border-radius: 5px;
  }
  