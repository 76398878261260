.home-section {
    height: calc(100vh - 100px);
    
    /* background: url('assets/images/coding-bg.jpg') no-repeat center center/cover; */

    
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  /* .home-content {
    
  } */
  
  .home-content h1 {
    font-size: 3rem;
  }
  
  .home-content p {
    font-size: 1.5rem;
  }
  
  .view-projects-btn {
    background-color: #3498db;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 20px;
  }
  